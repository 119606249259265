const initCalculateurEchantillon = () => {
    const populationInput = document.getElementById('population');
    const margeInput = document.getElementById('marge');
    const confianceInput = document.getElementById('confiance');
    const echantillonRequisOutput = document.getElementById('echantillon-requis');

    function calculateSampleSize() {
        const marge = parseFloat(margeInput.value);
        const confiance = parseFloat(confianceInput.value);
        const population = parseFloat(populationInput.value)
        if (isNaN(population) || isNaN(marge) || isNaN(confiance)) {
            echantillonRequisOutput.textContent = "Sélectionnez tous les paramètres";
            return;
        }

        const zScore = {
            80: 1.28,
            85: 1.44,
            90: 1.65,
            95: 1.96,
            99: 2.576,
        }[confiance];

        if (!zScore) {
            echantillonRequisOutput.textContent = "Niveau de confiance invalide";
            return;
        }

        const pop = population
        const p = 0.5;
        const q = 1 - p;
        const e = marge / 100;

        const sampleSize = Math.ceil(((zScore**2 * p * q) / (e**2))/(1+((zScore**2 * p * q)/(e**2 * pop))));

        echantillonRequisOutput.textContent = sampleSize || "Erreur dans le calcul";
    }

    if (populationInput && margeInput && confianceInput && echantillonRequisOutput) {
        margeInput.addEventListener('input', calculateSampleSize);
        populationInput.addEventListener('input', calculateSampleSize);
        confianceInput.addEventListener('input', calculateSampleSize);
    }
};

export { initCalculateurEchantillon };
