const initMethodology = () => {
    const sliders = document.getElementsByClassName('methodology_js');
    
    for(let slider of sliders) {
        for(let step of slider.children) {
            for(let forme_et_contenu of step.children) {
                for(let forme_ou_contenu of forme_et_contenu.children) {
                
                    let child = forme_ou_contenu
                        window.addEventListener('scroll', () => {
                            
                            let element_to_top = child.getBoundingClientRect().top;
                            let element_height = child.offsetHeight;
                            let window_height = window.innerHeight;

                            if(element_to_top <= window_height / 2) {
                                if(child.classList.contains('texte') || child.classList.contains('icone')){
                                    child.style.opacity = 1
                                } else if(child.classList.contains('line_container')) {
                                    for(let child_element of child.children[0].children) {
                                        if(child_element.classList.contains('line_animation')) {
                                            let percentage_passed = ((window_height / 2) - element_to_top) / (element_height)
                                            if(percentage_passed >= 0 && percentage_passed <= 1) {
                                                child_element.style.height = percentage_passed * 100 + '%'
                                            } else if (percentage_passed > 0) {
                                                child_element.style.height = '100%'
                                            }
                                        }

                                    }
                                    
                                }
                            } else {
                                if(child.classList.contains('texte') || child.classList.contains('icone')){
                                    child.style.opacity = 0.3
                                } else if(child.classList.contains('line_container')) {
                                    for(let child_element of child.children[0].children) {
                                        if(child_element.classList.contains('line_animation')) {
                                            child_element.style.height = '0%'
                                        }
                                    }
                                }
                            }
                        });
                }
            }
        }
    }
}

export { initMethodology };