const initMoveArticleParts = () => {
    let move_actions_lists = document.querySelectorAll('.move_list');
    for(let move_actions_list of move_actions_lists) {
        let move_up = move_actions_list.querySelector('.move_up');
        let move_down = move_actions_list.querySelector('.move_down');
        move_up.addEventListener('click', (event) => {
            move_element_up(move_up);
            save(move_up);
        });
        move_down.addEventListener('click', (event) => {
            move_element_down(move_down);
            save(move_down);
        });
    }
}

function move_element_up(move_up) {
    let part_element = move_up.parentElement.parentElement.parentElement;
    let previous_element = part_element.previousElementSibling;
    if(previous_element !== undefined) {
        previous_element.before(part_element)
    }
}

function move_element_down(move_down) {
    let part_element = move_down.parentElement.parentElement.parentElement;
    let after_element = part_element.nextElementSibling;
    if(after_element !== undefined) {
        after_element.after(part_element)
    }
}

function save(element) {
    let container_elements = element.parentElement.parentElement.parentElement.parentElement;
    for(let i = 0; i < container_elements.children.length; i++) {
        let rails_element_id = parseInt(container_elements.children[i].getAttribute('rails-id'));
        fetch(`https://www.etudiermonmarche.fr/api/v1/parties/${rails_element_id.toString()}`, {
            method: 'PATCH',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                partie_position: i
            }),
        }).then((res) => { console.log(res) }).catch((err) => { console.log(err)})
    }
}

export { initMoveArticleParts };