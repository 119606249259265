
// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "../controllers"
import "bootstrap"
// Internal imports, e.g:
// import { initSelect2 } from '../components/init_select2';
import { initCursor } from '../components/cursor';
import { initHEXModal } from '../components/hex_modal';
import { initINFCSlider } from '../components/infc_slider';
import { initMethodology } from '../components/methodology';
import { initItemToChoose } from '../components/item_to_choose';
import { initLiveEditor } from '../components/live_editor';
import { initTableauHexSearchBar } from '../components/tableau_hex_search_bar';
import { initMoveArticleParts } from '../components/move_article_parts';
import { initCalculateurEchantillon } from '../components/calculateur_echantillon';
import { initTest } from '../components/test';
import {initCookies} from "../components/cookies";

document.addEventListener('turbo:load', () => {
    // Call your functions here, e.g:
    // initCursor();
    initHEXModal();
    initINFCSlider();
    initMethodology();
    initItemToChoose();
    initLiveEditor();
    initTableauHexSearchBar();
    initMoveArticleParts();
    initCalculateurEchantillon();
    // initSelect2();
    initCookies();
    
    $(".hover").mouseleave(
        function() {
            $(this).removeClass("hover");
        }
    );
});